import React from 'react'
import "./About.css"

const About = () => {
    return (
        <section id="about">
            <div className='about-container'>
                <div className='about-heading' data-aos="flip-left">
                    <strong>About Me</strong>
                    <h3>Designing with passion for Problem solving</h3>
                    <a href="tel:0769838892" className='btn-link'>
                        +949838892 <i className="fa-solid fa-phone-flip"></i>
                    </a>
                </div>
                <div className='about-details' data-aos="flip-right">
                    <p>Highly passionate individual with strong leadership and communicationabilities. A dedicated team player who is excited about experiencing industrialexposures.</p>
                    <p>Highly passionate individual with strong leadership and communicationabilities. A dedicated team player who is excited about experiencing industrialexposures.</p>
                    <p>Highly passionate individual with strong leadership and communicationabilities. A dedicated team player who is excited about experiencing industrialexposures.</p>

                </div>
            </div>
        </section>
    )
}

export default About