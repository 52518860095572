import React from 'react';
import './Navigation.css'

const Navigation = () => {
    return (
        <nav className='navigation'>
            <a href='#!' className='logo'>Dev</a>
        </nav>
    )
}

export default Navigation