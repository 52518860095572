import React from 'react'
import "./Projects.css"

import AgrOM from "../../assets/Images/AgrOM.png"
import LMS from "../../assets/Images/ReadEra.png"

import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';


// import required modules
import { Navigation, Pagination } from 'swiper/modules';

const Projects = () => {

    const data = [
        {
            src: AgrOM,
            url: '#!'
        },
        {
            src: LMS,
            url: '#!'
        },

    ]
    return (
        <section id="projects" data-aos="fade">
            <div className='project-heading'>
                <h3>Recent Projects</h3>
            </div>

            <Swiper
                autoHeight={true}
                spaceBetween={20}
                navigation={true}
                pagination={{
                    clickable: true,
                }}
                modules={[Navigation, Pagination]}
                className="mySwiper"
            >
                {
                    data.map(project => (
                        <SwiperSlide key={project.src}>
                            <div className='project-box'>
                                <a href={project.src}>
                                    <img src={project.src} alt="project" />
                                    <div className='p-overlayer'>
                                        <strong>Outserved Reports</strong>
                                    </div>
                                </a>
                            </div>
                        </SwiperSlide>
                    ))
                }


            </Swiper>



            <div className='github-btn'>
                <a href="https://github.com/Sribarathvajasarma" className='btn-link'>
                    My Github <i className="fa-brands fa-github"></i>
                </a>
            </div>
        </section>
    )
}

export default Projects