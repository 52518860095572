import React from 'react';
import './Home.css';
import Avatar from '../../assets/Images/profile.jpg'


const Home = () => {
    return (
        <section id="home">
            <div className="home-text" data-aos="fade-down">
                <strong>Hello, It's me</strong>
                <h1>Barath</h1>
                <p>Highly passionate individual with strong leadership and communicationabilities. A dedicated team player who is excited about experiencing industrialexposures.</p>
                <a href="#summary" className='btn-link'>Scroll for Me</a>
            </div>

            <div className='home-img' data-aos="fade-up">
                <div className='img-box'>
                    <img src={Avatar} alt="Avatar" />
                    <h2>Barath<br /><span>Software Engineer</span></h2>

                    <div className='social'>
                        <a href='#!'>
                            <i className="fa-brands fa-facebook"></i>
                        </a>

                        <a href='#!'>
                            <i className="fa-brands fa-linkedin"></i>
                        </a>
                    </div>
                    <a href="#!" className='hire-me'>Hire Me</a>
                </div>

            </div>

        </section>
    )
}

export default Home