import React from 'react'
import './Summary.css'
import CV from '../../assets/CV/CV.pdf'

const Summary = () => {
    return (
        <section id="summary">
            <div className='summary-heading' data-aos="fade-right">
                <strong>My Skillset</strong>
                <h2>Leadership, Innovation, Critical thinking, Time management</h2>
                <a href={CV} download className='btn-link'>
                    Get CV <i className="fa-solid fa-download"></i>
                </a>
            </div>

            <div className='summary-details' data-aos="fade-left">
                <p>
                    Leadership, Innovation, Critical thinking, Time management
                    Leadership, Innovation, Critical thinking, Time management
                    Leadership, Innovation, Critical thinking, Time management
                    Leadership, Innovation, Critical thinking, Time management
                    Leadership, Innovation, Critical thinking, Time management
                    Leadership, Innovation, Critical thinking, Time management

                </p>

            </div>

        </section>
    )
}

export default Summary